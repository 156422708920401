import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { CreatePriceModifier, UpdatePriceModifier, ListPriceModifier } from '@/types/domains/PriceModifier'

const priceModifierService = authenticatedService('/v1')

const get = (serviceId: string): Promise<ListPriceModifier> => priceModifierService.get({}, `/price_modifiers?service_id=${serviceId}`).then(extractData)

const create = (priceModifier: CreatePriceModifier): Promise<any> => priceModifierService.post(priceModifier, 'price_modifiers').then(extractData)

const update = (priceModifierId: string, priceModifier: UpdatePriceModifier) => priceModifierService.patch(priceModifier, `price_modifiers/${priceModifierId}`)

priceModifierService.setServiceMethods({ get, create, update })

export const priceModifierDomain = priceModifierService.initialize
