import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

import type { CreatePickUpAddress, CreatePickUpAddressRes, ListPickUpAddressesRes } from '@/types/domains/PickUpAddress'
import { cleanPayload } from '@/utils/clean-payload'

const pickUpAddressService = authenticatedService('/v1/pick_up_addresses')

pickUpAddressService.setServiceMethods({
  async list ({ name, service_id, page, per_page }: {
    name: string
    service_id: string
    page: number
    per_page: number
  }): Promise<ListPickUpAddressesRes> {
    return await pickUpAddressService.get(cleanPayload({ name, service_id, page, per_page }), '').then(extractData)
  },
  async create (data: CreatePickUpAddress): Promise<CreatePickUpAddressRes> {
    return await pickUpAddressService.postFormData(cleanPayload(data), '').then(extractData)
  },
  async delete (groupId: string): Promise<void> {
    return await pickUpAddressService.remove({}, `/${groupId}`).then(extractData)
  }
})

export const pickUpAddressDomain = pickUpAddressService.initialize
