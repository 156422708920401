/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, keyframes, css } from '@emotion/core'
import { Label } from '../Label/Label'
import { flexColumn, flexRowCentered } from '../../assets/styles/layout'
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip'
import { colors } from '../../assets/styles/colors'

const toolTipTransition = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

export const errorTooltip = css`
  position: absolute;
  bottom: calc(100% - 30px);
  margin-bottom: 10px;
  animation: 0.3s ${toolTipTransition} ease-in;
  opacity: 0.8;
  pointer-events: none;
  z-index: 4;
`

const containerRelativePosition = css`
  position: relative;
`

const fullWidth = css`
  width: 100%;
`

const horizontalInputFormGroup = css`
  ${flexRowCentered};
  display: inline-flex;
  justify-content: flex-start;
`

const requiredField = css`
  color: ${colors.gray4};
  font-weight: 400;
  margin-left: 10px;
`

/**
 * @type {React.FC<{
 *   id?: string,
 *   errorMessage?: string,
 *   label?: string | React.ReactNode,
 *   horizontal?: boolean,
 *   children?: React.ReactNode,
 *   customCss?: import('@emotion/core').SerializedStyles[],
 *   [key: string]?: any
 *  }>}
 * */
export const InputFormGroup = ({
  id = undefined,
  errorMessage = '',
  label = '',
  horizontal,
  children: receivedChildren,
  customCss = [],
  customLabelCss = [],
  required = false,
  ...props
}) => {
  const hasError = Boolean(errorMessage)

  const children = React.Children.map(receivedChildren, (child) => {
    const { css, error } = child.props
    return React.cloneElement(child, { error: error || hasError, id, css: [css, horizontal && flexRowCentered] })
  }
  )

  return (
    <div
      {...props}
      css={[
        flexColumn,
        containerRelativePosition,
        !horizontal && fullWidth,
        horizontal && horizontalInputFormGroup,
        ...customCss]}
    >
      {hasError && (
        <ErrorTooltip css={[errorTooltip, horizontal && errorTooltipHorizontalForm]}>{errorMessage}</ErrorTooltip>
      )}
      {Boolean(label) && <Label htmlFor={id} css={[...customLabelCss]}>{label}{required && <span css={requiredField}>(Obrigatório)</span>}</Label>}

      {children}
    </div>
  )
}

const errorTooltipHorizontalForm = css`
  top: auto;
  bottom: 100%;
`

export const HorizontalInputFormGroup = props => <InputFormGroup horizontal {...props} />
