import { orbCore } from 'Core/Service/Orb/Service'
import { activityModel, activityAboutModel, activityUpdateModel, complementaryInfoModel } from './Model'
import { ActivityParser } from './Parser'
import { convertToFormData } from '@bonitour/common-functions'
import { CompanyStore } from 'Core/Store/Company'

const { activityDomain } = orbCore

export const ActivityService = {
  list (enabled) {
    return activityDomain.sellableList(enabled).then(ActivityParser.list)
  },
  create (data) {
    const companyId = CompanyStore.getId()
    const requestPayload = activityModel(data, companyId)
    return activityDomain.create(convertToFormData(requestPayload))
      .then(({ service = {} }) => service)
  },
  get (serviceId = '') {
    return activityDomain.get(serviceId)
      .then(ActivityParser.instance)
  },
  getRestrictions (serviceId = '') {
    return activityDomain.get(serviceId)
      .then(ActivityParser.restrictions)
  },
  updateMainActivity (companyId, data = {}) {
    const requestPayload = activityUpdateModel(data)
    return activityDomain.updateAsFormData(convertToFormData(requestPayload), companyId)
  },
  updateAbout (data = {}, serviceId = '') {
    const requestPayload = activityAboutModel(data)
    return activityDomain.update(requestPayload, serviceId)
  },
  updateActivityComplementaryInfo (data = {}, serviceId = '') {
    const requestPayload = complementaryInfoModel(data)
    return activityDomain.update(requestPayload, serviceId)
  }
}
