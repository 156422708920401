import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

import type { LinkedServicePickUpAddressesRes } from '@/types/domains/ServicePickUpAddress'

const servicePickUpAddressService = authenticatedService('/v1/service_pick_up_addresses')

servicePickUpAddressService.setServiceMethods({
  async linkedAddresses ({ serviceId }: {
    serviceId: string
  }): Promise<LinkedServicePickUpAddressesRes> {
    return await servicePickUpAddressService.get({}, `${serviceId}/linked_addresses`).then(extractData)
  },
  async updateAddresses ({ serviceId, pickUpAddressIds }: {
    serviceId: string
    pickUpAddressIds: string
  }): Promise<void> {
    return await servicePickUpAddressService.put({
      pick_up_address_ids: pickUpAddressIds
    }, serviceId).then(extractData)
  }
})

export const servicePickUpAddressDomain = servicePickUpAddressService.initialize
