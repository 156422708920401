/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Card } from '../Card/Card'
import { colors } from '../../assets/styles/colors'

const tabPosition = css`
  position: relative;
  z-index: 2;
`

const hidden = css`
  display: none;
`

const underlineTabPane = css`
  background: none;
  border: none;
  border-radius: 0;
  border-top: 3px solid ${colors.gray11};
`

export const TabPane = ({ selected = false, alwaysRerender = false, tabId = '', customCss = [], ...props }) => {
  const [wasSelected, setSelectedSituation] = useState(false)

  useEffect(() => {
    if (!wasSelected && selected) {
      setSelectedSituation(selected)
    }
  }, [wasSelected, selected, alwaysRerender])

  const shouldRender = alwaysRerender ? selected : wasSelected

  return shouldRender && <Card css={[tabPosition, !selected && hidden, ...customCss]} {...props} />
}

export const TabContainer = ({ activeTab = '', children }) => {
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      selected: Boolean(activeTab) && activeTab === child.props.tabId
    })
  })
  return <>{childrenWithProps}</>
}

export const UnderlineTabPane = ({ selected = false, alwaysRerender = false, tabId = '', children, ...props }) => {
  return (
    <TabPane css={underlineTabPane} tabId={tabId} selected={selected} alwaysRerender={alwaysRerender} {...props}>
      {children}
    </TabPane>
  )
}
