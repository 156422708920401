/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect, useCallback, useRef } from 'react'
import { identity } from '@bonitour/common-functions'
import { pageLimitDefaultOptions } from '../../constants/pageLimitOptions'
import { EmptyResult } from '../EmptyResult/EmptyResult'
import { Pagination } from '../Pagination/Pagination'
import { Select } from '../Select/Select'
import { Row } from '../Layout/Row/Row'
import { Column } from '../Layout/Column/Column'
import { flexColumn } from '../../assets/styles/layout'
import { marginBottom, marginLeft, marginRight } from '../../assets/styles/global'
import { scrollBar } from '../../assets/styles/addons'

const marginRight10 = marginRight(10)
const marginLeft10 = marginLeft(10)
const marginBottom10 = marginBottom(10)

const width95 = css`
  width: 95px;
`

const overflowXAuto = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

const container = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const emptyStyle = css`
  max-width: 100%;
  margin: 10px;
`

export const PaginationContainer = ({
  children,
  title = 'Não há informações',
  subtitle = 'Você não tem informações',
  total = 0,
  currentPage: containerCurrentPage = 1,
  onPagination: emitPaginationEvent = identity,
  pagesQuantity = 1,
  pageLimitDefault = 10,
  isOpenDrawer = false,
  pageLimitOptions = [],
  childrenCustomCss = [],
  pageRowCustomCss = [],
  isLoading = false,
  shouldEmitOnMount = true
}) => {
  const [currentPage, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(pageLimitDefault)
  const hasPageLimitation = pageLimitOptions.length <= 0
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (containerCurrentPage !== currentPage) {
      setPage(containerCurrentPage)
    }
    if (total <= pageLimit) {
      setPage(1)
    }
    // eslint-disable-next-line
  }, [total, containerCurrentPage])

  const changePageLimit = useCallback(value => {
    if (pageLimit !== value) {
      setPage(1)
    }
    setPageLimit(value)
  }, [pageLimit])

  useEffect(() => {
    if (isFirstRender.current && !shouldEmitOnMount) {
      isFirstRender.current = false
    } else {
      isFirstRender.current = false
      emitPaginationEvent(currentPage, pageLimit)
    }
  }, [emitPaginationEvent, currentPage, pageLimit, shouldEmitOnMount])

  if (total === 0 && !isLoading) {
    return (
      <EmptyResult css={emptyStyle} title={title} subtitle={subtitle} />
    )
  }

  return (
    <div css={flexColumn}>
      <Row css={overflowXAuto} customCss={childrenCustomCss}>{children}</Row>
      <Row customCss={pageRowCustomCss}>
        <Column css={container}>
          <div css={marginBottom10}>
            <span css={marginRight10}>Mostrar</span>
            <Select isOpenDrawer={isOpenDrawer} css={width95} value={pageLimit} options={hasPageLimitation ? pageLimitDefaultOptions : pageLimitOptions} onChange={changePageLimit} />
            <span css={marginLeft10}> de {total} itens</span>
          </div>
          <Pagination css={marginBottom10} quantity={pagesQuantity} active={currentPage} onChange={setPage} />
        </Column>
      </Row>
    </div>
  )
}
