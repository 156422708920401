import React, { useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { TableRow } from './Row'
import { CellContainer, TableContainer, submitButton } from './styles'
import { Checkbox } from '../Checkbox/Checkbox'
import { FloatingSelectionBar } from '../FloatingActionBar/FloatingBindingBar'
import { Button } from '../Buttons/Buttons'
import { CheckIcon } from '../Icons/Icons'
import { TableV2 } from './Table'
import { identity } from '@bonitour/common-functions'
import { nonFloatingButtonContainer, paginationCss } from './SelectableTable.style'
import { PaginationBar } from '../PaginationBar'

const PER_PAGE_OPTIONS = [10, 25, 50, 100]

const CheckboxCell = ({
  onChange,
  label,
  checked,
  className = '',
  disabled = false
}) => {
  return (
    <CellContainer className={className}>
      <Checkbox
        disabled={disabled}
        className='checkbox'
        onChange={onChange}
        checked={checked}
      />
      {label}
    </CellContainer>
  )
}
CheckboxCell.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string
}

export const SelectableTable = ({
  onSubmit = identity,
  onSelect = identity,
  labels,
  data,
  emptyMessage = 'Nenhum item encontrado',
  columnsWidths = [],
  customCss = [],
  sortedColumn = '',
  isLoading = false,
  isSubmitting = false,
  alreadyLinkedIds = [],
  showFloatingBar = true,
  pagination = {
    onChange: identity,
    isEnabled: false,
    perPage: 10,
    currentPage: 1,
    totalPages: undefined,
    total: undefined
  }
}) => {
  const [selectedIds, setSelectedIds] = useState(() => {
    return Array.from(alreadyLinkedIds)
  })

  useEffect(() => {
    setSelectedIds(alreadyLinkedIds)
  }, [alreadyLinkedIds])

  const allSelected = useMemo(
    () => !data.some(({ id }) => !selectedIds.includes(id)),
    [data, selectedIds]
  )

  const onSelectAll = useCallback(
    ev => {
      if (allSelected) {
        return setSelectedIds(selected =>
          selected.filter(id => !data.some(({ id: itemId }) => itemId === id))
        )
      } else {
        return setSelectedIds(selected =>
          Array.from(new Set([...selected, ...data.map(({ id }) => id)]))
        )
      }
    },
    [allSelected, data]
  )

  const labelsWithCheckbox = useMemo(
    () =>
      labels.map((label, idx) =>
        idx === 0
          ? (
            <CheckboxCell
              className='checkbox_header__container'
              onChange={onSelectAll}
              label={label}
              disabled={!data.length || isLoading}
              checked={allSelected}
              key={idx}
            />
          )
          : (
            label
          )
      ),
    [allSelected, data.length, isLoading, labels, onSelectAll]
  )

  const handleOnSelectRow = useCallback(
    itemId => {
      if (selectedIds.includes(itemId)) {
        return setSelectedIds(selectedIds.filter(id => id !== itemId))
      } else {
        return setSelectedIds([...selectedIds, itemId])
      }
    },
    [selectedIds]
  )

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedIds)
  }, [onSubmit, selectedIds])

  const isDirty = useMemo(
    () => alreadyLinkedIds.some(id => !selectedIds.includes(id)) || selectedIds.some(id => !alreadyLinkedIds.includes(id))
    , [alreadyLinkedIds, selectedIds]
  )

  return (
    <>
      <TableV2
        isLoading={isLoading}
        labels={labelsWithCheckbox}
        customCss={[TableContainer, ...customCss]}
        sortedColumn={sortedColumn}
        columnsWidths={columnsWidths}
        emptyMessage={emptyMessage}
      >
        {data.map(({ id, ...rowItems }, idx) => {
          const rowValues = Object.values(rowItems)
          const firstCellLabel = rowValues.splice(0, 1)[0]

          return (
            <TableRow
              className={
                selectedIds.includes(id) || allSelected
                  ? 'table_row__selected'
                  : ''
              }
              onClick={() => handleOnSelectRow(id)}
              key={idx}
            >
              {[
                <CheckboxCell
                  key={idx}
                  onChange={() => handleOnSelectRow(id)}
                  checked={selectedIds.includes(id) || allSelected}
                  label={firstCellLabel}
                />,
                ...rowValues
              ]}
            </TableRow>
          )
        })}
      </TableV2>

      {pagination.isEnabled
        ? <PaginationBar
          customCss={paginationCss}
          currentPage={pagination.currentPage}
          onPageChange={(page) => pagination.onChange({ currentPage: page })}
          onPerPageChange={(perPage) => pagination.onChange({ perPage })}
          perPage={pagination.perPage}
          totalPages={pagination.totalPages}
          perPageOptions={PER_PAGE_OPTIONS}
          totalElements={pagination.totalEntries}
          total={pagination.total || 0}
        />
        : null}

      {showFloatingBar
        ? <FloatingSelectionBar selected={selectedIds}>
          <Button
            onClick={handleOnSubmit}
            css={submitButton}
            disabled={!isDirty || isLoading || isSubmitting}
          >
          Salvar alterações <CheckIcon />
          </Button>
        </FloatingSelectionBar>
        : <div css={nonFloatingButtonContainer}>
          <Button css={submitButton} disabled={!isDirty || isLoading || isSubmitting} onClick={handleOnSubmit}>
            Salvar alterações
          </Button>
        </div>}
    </>
  )
}

SelectableTable.propTypes = {
  labels: PropTypes.any,
  data: PropTypes.any.isRequired,
  columnsWidths: PropTypes.arrayOf(PropTypes.string),
  customCss: PropTypes.arrayOf(PropTypes.string),
  sortedColumn: PropTypes.string
}
