/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'

import { scrollIntoView } from './Select.functions'
import { useSelect, useSelectPosition } from './Select.hooks'
import { drawerPosition, selectInputText, hidden, drawerPositionVariant } from './Select.style'
import { SelectOption } from './SelectOption/SelectOption'
import { StandaloneSelect } from './StandaloneSelect'
import { Drawer, DrawerWithPortal } from '../Drawer/Drawer'

const isNotDefined = (value) => value === null || value === undefined

/**
 * @typedef {{
 *  onInvalid?: function,
 *  error?: boolean | string | number,
 *  customCss?: import('@emotion/core').SerializedStyles[],
 *  placeholder?: string,
 *  value?: any,
 *  options: {  value: unknown; label: string;}[],
 *  disabled?: boolean,
 *  onChange?: function,
 *  onBlur?: function,
 *  labelStyle?: import('@emotion/core').SerializedStyles,
 *  optionStyle?: import('@emotion/core').SerializedStyles,
 *  arialLabel?: string,
 *  mobileHeader?: any,
 *  optionIcon?: any,
 *  openSelector?: any,
 *  id?: string,
 *  usePortal?: boolean,
 * }} InputExtendedProps
 *
 * @typedef {InputExtendedProps & React.SelectHTMLAttributes<HTMLSelectElement>} InputProps
 *
 * @type {(props: InputProps) => React.ReactElement}
*/
export const Select = ({
  placeholder = '',
  value: selectedValue,
  options = [],
  disabled = false,
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  labelStyle,
  optionStyle,
  ariaLabel,
  mobileHeader,
  optionIcon,
  openSelector = false,
  isOpenDrawer = false,
  usePortal = false,
  onChangeWholeEvent = false,
  ...other
}) => {
  const optionsRefs = useRef([])
  const selectRef = useRef()
  const inputRef = useRef()
  const optionsContainerRef = useRef()

  const [selectedIndex, setSelectedIndex] = useState()
  const [isVisible, setVisibility] = useClickOutside(selectRef, optionsContainerRef)
  const { selectInput, availableOptions, setSelectInput, filteredOptions } = useSelect(options)
  const { focusedIndex, setFocusedIndex, step } = useSelectPosition(filteredOptions)

  const isDisabled = useMemo(() => (disabled || options.length === 0), [options, disabled])

  useEffect(() => { if (openSelector) { setVisibility(true); openSelector = false } }, [openSelector])

  const onBlur = useCallback(() => {
    setSelectInput('')
    emitBlurEvent(selectedValue)
  }, [selectedValue, emitBlurEvent])

  const onInputChange = useCallback((event) => {
    event.stopPropagation()
    setSelectInput(event.target.value)
  }, [isDisabled])

  const setSelectValue = useCallback((value, event) => {
    setVisibility(false)
    emitChangeEvent(onChangeWholeEvent ? event : value)
  }, [emitChangeEvent])

  const selectValueByIndex = useCallback((index) => {
    const { value } = options?.[index] ?? {}
    setSelectValue(value)
  }, [options, setSelectValue])

  useEffect(() => {
    const selectedIndex = options.findIndex(({ value }) => value === selectedValue)
    if (selectedIndex !== -1) {
      onBlur()
      setSelectedIndex(selectedIndex)
    } else {
      setSelectedIndex()
    }
  }, [options, selectedValue])

  useEffect(() => {
    if (isVisible && selectRef.current && selectRef.current.scrollIntoView) {
      selectRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible && focusedIndex !== undefined && optionsRefs.current.length) {
      scrollIntoView(optionsContainerRef.current, optionsRefs.current[focusedIndex])
    }
  }, [isVisible, focusedIndex, optionsContainerRef, optionsRefs])

  const selectedLabel = options[selectedIndex] && options[selectedIndex].label
  const [inputPosition, setInputPosition] = useState()
  const updateInputPosition = () => setInputPosition(selectRef.current?.getBoundingClientRect())
  useEffect(() => {
    if (!usePortal) {
      return
    }
    updateInputPosition()
    window.addEventListener('resize', updateInputPosition)
    window.addEventListener('scroll', updateInputPosition)
    return () => {
      window.removeEventListener('resize', updateInputPosition)
      window.removeEventListener('scroll', updateInputPosition)
    }
  }, [])

  const DrawerComponent = useMemo(() => (usePortal ? DrawerWithPortal : Drawer), [usePortal])

  const handleKeydownEvents = (event) => {
    switch (event.key) {
    case 'Tab':
      if (isVisible) {
        setVisibility(false)
        onBlur()
        setFocusedIndex(0)
      }
      break
    case 'Escape':
      if (isVisible) {
        setVisibility(false)
        onBlur()
        setFocusedIndex(0)
      }
      break
    case 'Enter':
      if (isVisible) {
        if (availableOptions) {
          selectValueByIndex(focusedIndex)
        }
        setVisibility(false)
      } else {
        setVisibility(true)
      }
      break
    case 'ArrowUp':
      event.preventDefault()
      if (!isVisible) {
        setVisibility(true)
      }
      if (availableOptions) {
        step(-1)
      }
      break
    case 'ArrowDown':
      event.preventDefault()
      if (!isVisible) {
        setVisibility(true)
      }
      if (availableOptions) {
        step(1)
      }
      break
    default:
      if (!isVisible) {
        setVisibility(true)
      }
    }
  }

  const handleContainerEvents = (event) => {
    event.stopPropagation()
    switch (event.type) {
    case 'click':
      setVisibility(previousVisibility => !previousVisibility)
      inputRef.current.focus()
      break
    case 'keydown':
      handleKeydownEvents(event)
    }
  }

  const handleOptionsEvents = (value, index, event) => {
    event.stopPropagation()
    switch (event.type) {
    case 'click':
      if (isNotDefined(value)) {
        setVisibility(false)
        return
      }
      setSelectValue(value, event)
      break
    case 'keydown':
      handleKeydownEvents(event)
    }
  }

  const handleInputEvents = (event) => {
    event.stopPropagation()
    switch (event.type) {
    case 'keydown':
      handleKeydownEvents(event)
    }
  }

  const closeSelect = () => {
    setVisibility(false)
    onBlur()
  }

  const onOptionMouseEnter = (_value, index) => setFocusedIndex(index)

  return (
    <StandaloneSelect
      {...other}
      onClick={handleContainerEvents}
      onKeyDown={handleContainerEvents}
      isOpened={isVisible}
      ref={selectRef}
      onBlur={onBlur}
      disabled={isDisabled}
    >
      <input
        data-testid='select-input'
        ref={inputRef}
        disabled={isDisabled}
        css={selectInputText}
        onKeyDown={handleInputEvents}
        onBlur={onBlur}
        value={selectInput}
        placeholder={selectedLabel || placeholder}
        onChange={onInputChange}
        aria-label={ariaLabel}
      />

      {
        isVisible && !isDisabled && (
          <DrawerComponent
            customCss={isOpenDrawer ? [drawerPositionVariant] : [drawerPosition]}
            ref={optionsContainerRef}
            mobileHeader={mobileHeader}
            position={inputPosition}
          >
            {
              (availableOptions === 0) && (
                <SelectOption onClick={closeSelect}>
                  Sem opções
                </SelectOption>
              )
            }
            {
              filteredOptions.map(({ value, label, isVisible = true }, index, srcList) => (
                <SelectOption
                  key={value}
                  selected={value === selectedValue}
                  isFocused={index === focusedIndex}
                  css={[optionStyle, !isVisible && hidden]}
                  onClick={e => handleOptionsEvents(value, index, e)}
                  onKeyDown={e => handleOptionsEvents(value, index, e)}
                  onMouseEnter={
                    srcList.length >= 30
                      ? undefined
                      : e => onOptionMouseEnter(value, index, e)
                  }
                  index={index}
                  value={value}
                  ref={ref => { optionsRefs.current[index] = ref }}
                  optionIcon={optionIcon}
                >
                  {label}
                </SelectOption>
              ))
            }
          </DrawerComponent>
        )
      }
    </StandaloneSelect>
  )
}
